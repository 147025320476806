import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'leaflet/dist/leaflet.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

ReactDOM.render(
    <Router>
        <Switch>
            {/* Define your routes here */}
            <Route exact path="/" component={App}/>
        </Switch>
    </Router>,
    document.getElementById('root')
);
