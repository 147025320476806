import React, {useRef} from 'react';
import {Circle, Map, Popup, TileLayer} from 'react-leaflet';
import './App.css';
import {useLocation} from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const defaultCenter = [38.9072, -77.0369];
const defaultZoom = 8;

function App() {
    const mapRef = useRef();

    const query = useQuery();

    const queryParams = {
        center: query.get('q')?.split(","),
        radius: query.get('r') ? query.get('r') : "0",
        text: query.get('t'),
    };

    return (
        <div className="App">
            <Map ref={mapRef} center={queryParams.center ? queryParams.center : defaultCenter} zoom={defaultZoom}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                           attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"/>
                <Circle center={queryParams.center ? queryParams.center : defaultCenter}
                        pathOptions={{fillColor: 'blue'}}
                        radius={Number(queryParams.radius * 1000)}>
                    {queryParams.text &&
                        <Popup>
                            {queryParams.text}
                        </Popup>
                    }
                </Circle>
            </Map>
        </div>
    );
}

export default App;
